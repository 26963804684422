import {
  initPlasmicLoader,
  PlasmicRootProvider,
  PlasmicComponent,
  ComponentRenderData,
} from '@plasmicapp/loader-react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { PLASMIC } from './plasmic-init';
import './assets/styles/global.css'
import Lottie from 'lottie-react';
import { addContactMessage, subscribeToEmailList } from './hooks/FirebaseFunctions';
import { Helmet } from 'react-helmet-async';
import ReactGA from 'react-ga4';

function App() {
  const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
  
  ReactGA.initialize(TRACKING_ID);
  return (
    <PlasmicRootProvider loader={PLASMIC}>
      <Router>
        <Routes>
          <Route path="/*" element={CatchAllPage()} />
        </Routes>
      </Router>
    </PlasmicRootProvider>
  );
}

// We try loading the Plasmic page for the current route.
// If it doesn't exist, then return "Not found."
export function CatchAllPage() {
  const [loading, setLoading] = useState(true);
  const [pageData, setPageData] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [messageSubject, setMessageSubject] = useState('');
  const [messageBody, setMessageBody] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [success, setSuccess] = useState(false);
  const [file, setFile] = useState(null);

  useEffect(() => {
    console.log("Loading page data for", window.location.pathname);
    async function load() {
      const pageData = await PLASMIC.maybeFetchComponentData(window.location.pathname);
      setPageData(pageData);
      // console.log(pageData);
      setLoading(false);
    }
    load();
  }, []);

  if (loading) {
    // Center the loading indicator
    return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Lottie
        animationData={require('./assets/animations/loading-animation.json')}
        loop={true}
        style={{ width: 200, height: 200 }}
      />
    </div>;
  }
  if (!pageData) {
    return <div>Not found</div>;
  }
  // Access the text fields inside the SignUpPrompt component from Plasmic
  return (
    <>
      <Helmet>
        <title>{pageData.entryCompMetas[0].pageMetadata.title}</title>
        <meta name="description" content={pageData.entryCompMetas[0].pageMetadata.description} />
        <meta name="keywords" content={pageData.entryCompMetas[0].metadata.keywords} />
        <meta name='canonical' content={pageData.entryCompMetas[0].pageMetadata.canonical} />

        <meta property="og:title" content={pageData.entryCompMetas[0].pageMetadata.title} />
        <meta property="og:description" content={pageData.entryCompMetas[0].pageMetadata.description} />
        <meta property="og:image" content={pageData.entryCompMetas[0].pageMetadata.openGraphImageUrl} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />

        <meta name="twitter:title" content={pageData.entryCompMetas[0].pageMetadata.title} />
        <meta name="twitter:description" content={pageData.entryCompMetas[0].pageMetadata.description} />
        <meta name="twitter:image" content={pageData.entryCompMetas[0].pageMetadata.openGraphImageUrl} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <PlasmicComponent component={window.location.pathname}
        componentProps={{

          subscribePrompt:
          {
            subscribeFName: {
              props: {
                value: firstName,
                onChange: (e) => {
                  setFirstName(e.target.value);
                }
              }
            },
            subscribeLName: {
              props: {
                value: lastName,
                onChange: (e) => {
                  setLastName(e.target.value);
                }
              }
            },
            subscribeEmail: {
              props: {
                value: email,
                onChange: (e) => {
                  setEmail(e.target.value);
                },
              },
            },
            subscribeButton: {
              props: {
                onClick: () => {
                  subscribeToEmailList({
                    firstName: firstName,
                    lastName: lastName,
                    email: email
                  }).then((success) => {
                    setFirstName('');
                    setLastName('');
                    setEmail('');
                    setSuccess(true);
                  }).catch((error) => {
                    console.log(error);
                  });
                }
              }
            },
            subscribeSuccessMessage: {
              props: {
                children: success ? 'You\'ve been subscribed to our email list. Thanks for signing up!' : ''
              }
            },
          },
          fName: {
            props: {
              value: firstName,
              onChange: (e) => {
                setFirstName(e.target.value);
                console.log(firstName);
              }
            }
          },
          lName: {
            props: {
              value: lastName,
              onChange: (e) => {
                setLastName(e.target.value);
                console.log(lastName);
              }
            }
          },
          email: {
            props: {
              value: email,
              onChange: (e) => {
                setEmail(e.target.value);
                // console.log(email);
              }
            }
          },
          phone: {
            props: {
              value: phoneNumber,
              onChange: (e) => {
                setPhoneNumber(e.target.value);
                // console.log(phoneNumber);
              }
            }
          },
          messageSubject: {
            props: {
              value: messageSubject,
              onChange: (e) => {
                setMessageSubject(e.target.value);
                // console.log(messageSubject);
              }
            }
          },
          messageBody: {
            props: {
              value: messageBody,
              onChange: (e) => {
                setMessageBody(e.target.value);
                // console.log(messageBody);
              }
            }
          },
          sendMessageButton: {
            props: {
              onClick: () => {
                addContactMessage({
                  firstName: firstName,
                  lastName: lastName,
                  email: email,
                  phoneNumber: phoneNumber,
                  messageSubject: messageSubject,
                  messageBody: messageBody
                }).then((success) => {
                  // Clear the form
                  setFirstName('');
                  setLastName('');
                  setEmail('');
                  setPhoneNumber('');
                  setMessageSubject('');
                  setMessageBody('');
                  setSuccess(true);
                }).catch((error) => {
                  console.log(error);
                });
              }
            }
          },
          // if success is true, show the success message in the contactMessage component
          contactSuccessMessage: {
            props: {
              children: success ? 'Message sent successfully! We\'ll be in touch shortly.' : ''
            }
          },
          careerFName: {
            props: {
              value: firstName,
              onChange: (e) => {
                setFirstName(e.target.value);
                console.log(firstName);
              }
            }
          },
          careerLName: {
            props: {
              value: lastName,
              onChange: (e) => {
                setLastName(e.target.value);
                console.log(lastName);
              }
            }
          },
          careerEmail: {
            props: {
              value: email,
              onChange: (e) => {
                setEmail(e.target.value);
                console.log(email);
              }
            }
          },
          careerPhoneNumber: {
            props: {
              value: phoneNumber,
              onChange: (e) => {
                setPhoneNumber(e.target.value);
                console.log(phoneNumber);
              }
            }
          },
          careerBody: {
            props: {
              value: messageBody,
              onChange: (e) => {
                setMessageBody(e.target.value);
                console.log(messageBody);
              }
            }
          },
          careerUpload: {
            props: {
              onFilesChange: (file) => {
                if (file[0].status === 'done') {
                  setFile(file);
                  console.log(file);
                }
              }
            }
          },
          careerSubmitBtn: {
            props: {
              onClick: () => {
                console.log(firstName, lastName, email, phoneNumber, messageBody, file);
              }
            }
          },
        }}
      />
    </>
  );

}


export default App;